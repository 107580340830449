//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: ルーティング定義
//  Workfile:: routes.tsx
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//  ページ追加の際は、ユーザ毎ページ表示許可設定 store/conf/TrainMonitorGroup.json にも追加すること
//  設定が無い場合は白画面/「404 Not Found」の強制表示
//  およびコンソールログ「GROUP_UNREGISTERED」が表示される
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import React from 'react'

/****************************************************************************/
/*	定義値                                          												*/
/****************************************************************************/
// ページ定義
const FormListMap = React.lazy(() => import('./View/Map/FormListMap'))
const FormList = React.lazy(() => import('./View/FormList/FormListGrid'))
const TrbNowList = React.lazy(() => import('./View/TroubleList/TrbNowListGrid'))
const TrbAllList = React.lazy(() => import('./View/TroubleList/TrbAllListGrid'))
const FormDetail = React.lazy(() => import("./View/FormDetail/FormDetail"));
const FormDataPlot = React.lazy(() => import("./View/FormDataPlot/FormDataPlot"));
const FormDataPicker = React.lazy(() => import("./View/FormDataPicker/FormDataPicker"));
const FormPickDataPlot = React.lazy(() => import("./View/FormDataPicker/FormPickDataPlot"));


// コンテンツパス設定
const routes = [
	{ path: "FormList", name: "リアルタイム車両状態", index: true, element: FormList },
	{ path: "FormListMap", name: "地図表示", index: false, element: FormListMap },
	{ path: "TrbNowList", name: "故障一覧", index: false, element: TrbNowList },
	{ path: "TrbAllList", name: "故障解析", index: false, element: TrbAllList },
	{ path: "FormDataPlot", name: "車両データ", index: false, element: FormDataPlot },
	{ path: "FormDetail", name: "車両詳細表示", index: false, element: FormDetail },
	{ path: "FormDataPicker", name: "任意項目抽出", index: false, element: FormDataPicker },
	{ path: "FormPickDataPlot", name: "任意項目表示", index: false, element: FormPickDataPlot }
];


export default routes
