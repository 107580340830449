//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: ユーザー名Slice
//  Workfile:: userNameSlice.ts
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import { createSlice } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setSecureItem, getSecureItem } from "./SessionStorage";

/****************************************************************************/
/*	定義値                                          												*/
/****************************************************************************/
export const userNameSlice = createSlice({
	name: "userName",
	initialState: {
		strUserName: "",
		strUserGroup: "",
		strSessionID: getSecureItem("strSessionID") || "" // 初期値をSecure Storageから取得
	},
	reducers: {
		setUserName: (state, action) => {
			state.strUserName = action.payload;
		},
		setUserGroup: (state, action) => {
			state.strUserGroup = action.payload;
		},
		setSessionID: (state, action) => {
			state.strSessionID = action.payload;
			setSecureItem("strSessionID", action.payload); // Secure Storageに保存
		}
	}
});

export const { setUserName, setUserGroup, setSessionID } = userNameSlice.actions;
export default userNameSlice.reducer;
