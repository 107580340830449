//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: パスワード再設定ページ表示
//  Workfile:: PasswordReset.tsx
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import React, { useEffect, useState, useRef } from "react";
import { Alert, CssBaseline, Box, Grid, Link } from "@mui/material";
import { sendPasswordResetEmail, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./Firebase";
import * as common from "../../utils/common";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LockResetIcon from '@mui/icons-material/LockReset';
import UndoIcon from '@mui/icons-material/Undo';
import { BackgroundVanta } from "../../components/background/Background_vanta"; 

/****************************************************************************/
/*	定義値                                          						            */
/****************************************************************************/
const NAVIGATE_WAIT_TIME = 5000;       // ページ遷移待ち時間(msec)

//****************************************************************************
//++module		PasswordReset
//----------------------------------------------------------------------------
//++outline
//				パスワード再設定ページ表示
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
const PasswordReset = () => {
  const [email, setEmail] = useState("");           // メールアドレス
  const [success, setSuccess] = useState(false);    // 送信結果
  const [error, setError] = useState(null);         // エラーメッセージ
  const navigate = useNavigate();
  const [isBackgroundVisible, setBackgroundVisible] = useState(true);

	//****************************************************************************
	//++module		handleSubmit
	//----------------------------------------------------------------------------
	//++outline
	//				ボタン処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // 送信結果の初期化
    setSuccess(false);
    setError(null);

    // パスワード再設定メール送信
    sendResetEmail(email);
  };

	//****************************************************************************
	//++module		sendResetEmail
	//----------------------------------------------------------------------------
	//++outline
	//				パスワード再設定メール送信処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
  const sendResetEmail = (email: string) => {
    // パスワード再設定メール送信
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // メールが送信できた場合は、送信完了メッセージを表示し、
        // ログインページに自動遷移
        setSuccess(true);
        setTimeout(() => {
          navigate(common.PAGE_LOGIN);
        }, NAVIGATE_WAIT_TIME);
      })
      .catch((err) => {
        // メールが送信できなかった場合は、エラーメッセージを表示
        console.log(err.message);
        setError(err.message);
      });
  };

  const blDidEffect = useRef(false);    // useEffect初回フラグ
  //****************************************************************************
  //++module		useEffect
  //----------------------------------------------------------------------------
  //++outline
  //				useEffect処理
  //++arguments
  //
  //++supp
  //
  //++end_module
  //****************************************************************************
  useEffect(() => {
    if( !blDidEffect.current ){
      blDidEffect.current = true;
      signOut(auth);  // ログアウト
    }
  })
  
  return (
    <div>
      {isBackgroundVisible && <BackgroundVanta />}
    <div className="container">
      <CssBaseline />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <div style={{ display: "flex", justifyContent: "center", 
                    alignItems: "center", fontSize: "30px" ,  color: 'white',
                     fontFamily: '"Yu Gothic Medium", "游ゴシック Medium"',
                     lineHeight: '1.4',
                     textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                  パスワード再設定
        </div>
                <TextField
                  className="inputlogin"
                  name="email"
                  type="email"
                  value={email}
                  autoComplete="off"
                  // sx={{ width: '80%' }}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ 
                    color: 'black',
                    backgroundColor: 'white',
                    borderRadius: '4px', // 角丸の半径を指定します
                    overflow: 'hidden', // 子要素が角丸の外側に出ないようにします
                  }}
                />
          <Button variant="contained" type="submit" startIcon={<LockResetIcon />}>
          送信
					</Button>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item>
            <Link href={common.PAGE_LOGIN} variant="body2">
              <Button variant="outlined" startIcon={<UndoIcon />}>
                戻る
              </Button>
            </Link>
            </Grid>
          </Grid>
        </Box>
        
      {error && (
        <Alert severity="error">メールアドレスに送信できませんでした</Alert>
      )}
      {success && (
        <Alert severity="success">メールアドレスに送信しました</Alert>
      )}
    </div>
    </div>
  );
}

export default PasswordReset;