import React, { useState, useEffect, useRef } from "react";
import * as THREE from "three";
import BIRDS from "vanta/dist/vanta.birds.min";
import CLOUDS from 'vanta/dist/vanta.clouds.min';
import CLOUDS2 from 'vanta/dist/vanta.clouds2.min';
import WAVES from "vanta/dist/vanta.waves.min.js";
import RINGS from "vanta/dist/vanta.rings.min.js";
import FOG from "vanta/dist/vanta.fog.min.js";
import GLOBE from "vanta/dist/vanta.globe.min.js";
import HALO from "vanta/dist/vanta.halo.min.js";
import NET from "vanta/dist/vanta.net.min.js";

const effectsoption = [
  {
    "CLOUDS": {
      name: "CLOUDS",
      options: {
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: `${window.innerHeight}`,
        minWidth: `${window.innerWidth}`,
        speed: 1.0,
        backgroundColor: 0xffffff,
        skyColor: 0x82cafF,
        cloudColor: 0xffffff,
        cloudShadowColor: 0x666666,
        sunColor: 0x999919,
        sunGlareColor: 0xe32b00,
        sunlightColor: 0xff9933,
        enableDamping: true,
        dampingFactor: 0.25,
      },
    }
  },
  {
    "NET": {
      name: "NET",
      options: {
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: `${window.innerHeight}`,
        minWidth: `${window.innerWidth}`,
        scale: 1.0,
        scaleMobile: 1.0,
        color: 0x60ff3f,
        backgroundColor: 0x1a0d39,
        points: 18.0,
        maxDistance: 28.0,
        spacing: 19.0,
      }
    },
  },
  {
    "FOG": {
      name: "FOG",
      options: {
        // el: vantaRef.current,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: `${window.innerHeight}`,
        minWidth: `${window.innerWidth}`,
        highlightColor: 0xccd97,
        midtoneColor: 0x23a7a,
        lowlightColor: 0x2902d9,
        baseColor: 0xe1d9d9,
        blurFactor: 0.45,
        speed: 2.20,
      }
    },
  },
  {
    "GLOBE": {
      name: "GLOBE",
      options: {
        // el: vantaRef.current,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: `${window.innerHeight}`,
        minWidth: `${window.innerWidth}`,
        scale: 1.00,
        scaleMobile: 1.00,
        color: 0x3fff6c,
        size: 1.50,
        backgroundColor: 0x221241
      }
    }
  }, {
    "HALO": {
      name: "HALO",
      options: {
        // el: vantaRef.current,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: `${window.innerHeight}`,
        minWidth: `${window.innerWidth}`,
        baseColor: 0x1832af,
        backgroundColor: 0xc0c37,
        amplitudeFactor: 1.50,
        yOffset: 0.03,
        size: 1.60
      }
    }
  }, {
    "RINGS": {
      name: "RINGS",
      options: {
        // el: vantaRef.current,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: `${window.innerHeight}`,
        minWidth: `${window.innerWidth}`,
        scale: 1.00,
        scaleMobile: 1.00,
        color: 0x88ff00,
        backgroundColor: 0x202428,
        backgroundAlpha: 1
      }
    }
  },
  { "WAVES":{
  name: "WAVES",
    options: {
      // el: vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: `${window.innerHeight}`,
      minWidth: `${window.innerWidth}`,
      color: 0x5588,
      shininess: 30,
      waveHeight: 15,
      waveSpeed: 3,
      zoom: 0.5
    }
  }
}, {
  "CLOUDS2": {
    name: "CLOUDS2",
      options: {
      // el: vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: `${window.innerHeight}`,
      minWidth: `${window.innerWidth}`,
      scale: 1.00,
     speed: 0.80,
     backgroundColor: 0xb9d4,
      cloudColor: 0x95a2c0,
      lightColor: 0xfafafa,
    }
  }
  }, {
    "BIRDS": {
      name: "BIRDS",
      options: {
        // el: vantaRef.current,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 600.0,
        minWidth: 600.0,
        scale: 1.0,
        scaleMobile: 1.0
      }
    }
}
];


export const BackgroundVanta = () => {
  const [vantaEffect, setVantaEffect] = useState<any>(0);
  const vantaRef = useRef<any>(null);



  useEffect(() => {
    let effect = null;

    // const effect = window.vanta[effectConfig.name](Object.assign({}, effectConfig.options, { el: vantaRef.current }));

    const initializeVanta = () => {
      effect = CLOUDS({
        el: vantaRef.current,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: `${window.innerHeight}`,
        minWidth: `${window.innerWidth}`,
        speed: 1.0,
        backgroundColor: 0xffffff,
        skyColor: 0x62b0fF,
        cloudColor: 0xffffff,
        cloudShadowColor: 0x666666,
        sunColor: 0x999919,
        sunGlareColor: 0xe32b00,
        sunlightColor: 0xff9933,
        enableDamping: true, // enable damping for smoother motion
        dampingFactor: 0.25 //
      });
    };
  
    //  NET({
    //       el: vantaRef.current,
    //       THREE: THREE,
    //       mouseControls: true,
    //       touchControls: true,
    //       gyroControls: false,
    //       minHeight: `${window.innerHeight}`,
    //       minWidth: `${window.innerWidth}`,
    //       scale: 1.00,
    //       scaleMobile: 1.00,
    //       color: 0x60ff3f,
    //       backgroundColor: 0x1a0d39,
    //       points: 18.00,
    //       maxDistance: 28.00,
    //       spacing: 19.00
    //     })
    // effect = FOG({
    //       el: vantaRef.current,
    //       THREE: THREE,
    //       mouseControls: true,
    //       touchControls: true,
    //       gyroControls: false,
    //       minHeight: `${window.innerHeight}`,
    //       minWidth: `${window.innerWidth}`,
    //       highlightColor: 0xccd97,
    //       midtoneColor: 0x23a7a,
    //       lowlightColor: 0x2902d9,
    //       baseColor: 0xe1d9d9,
    //       blurFactor: 0.45,
    //       speed: 2.20,
    //     })};
  //       effect =  GLOBE({
  //         el: vantaRef.current,
  //         THREE: THREE,
  //         mouseControls: true,
  //         touchControls: true,
  //         gyroControls: false,
  //         minHeight: `${window.innerHeight}`,
  //         minWidth: `${window.innerWidth}`,
  //         scale: 1.00,
  //         scaleMobile: 1.00,
  //         color: 0x3fff6c,
  //         size: 1.50,
  //         backgroundColor: 0x221241
  // })};
      //   effect =  HALO({
      //     el: vantaRef.current,
      //     THREE: THREE,
      //     mouseControls: true,
      //     touchControls: true,
      //     gyroControls: false,
      //     minHeight: `${window.innerHeight}`,
      //     minWidth: `${window.innerWidth}`,
      //       baseColor: 0x1832af,
      //     backgroundColor: 0xc0c37,
      //     amplitudeFactor: 1.50,
      //     yOffset: 0.03,
      //     size: 1.60
      //   })
      // };
        // RINGS
      //   effect =  RINGS({
      //     el: vantaRef.current,
      //     THREE: THREE,
      //     mouseControls: true,
      //     touchControls: true,
      //     gyroControls: false,
      //     minHeight: `${window.innerHeight}`,
      //     minWidth: `${window.innerWidth}`,
      //     scale: 1.00,
      //     scaleMobile: 1.00,
      //     color:0x88ff00,
      //     backgroundColor:0x202428,
      //     backgroundAlpha:1
      //   });
      // };

        // WAVES
        // effect =   WAVES({
        //   el: vantaRef.current,
        //   THREE: THREE,
        //   mouseControls: true,
        //   touchControls: true,
        //   gyroControls: false,
        //   minHeight: `${window.innerHeight}`,
        //   minWidth: `${window.innerWidth}`,
        //   color: 0x5588,
        //   shininess: 30,
        //   waveHeight:15,
        //   waveSpeed:3,
        //   zoom:0.5          
        // })};
      

        //CLOUDS22
        // CLOUDS2({
        //   el: vantaRef.current,
        //   THREE: THREE,
        //   mouseControls: true,
        //   touchControls: true,
        //   gyroControls: false,
        //   minHeight: `${window.innerHeight}`,
        //   minWidth: `${window.innerWidth}`,
        //   scale: 1.00,
        //   speed: 0.80,
        //   backgroundColor: 0xb9d4,
        //   cloudColor: 0x95a2c0,
        //   lightColor: 0xfafafa,
  
        // })
      
        //BIRDS
        // BIRDS({
        //   el: vantaRef.current,
        //   THREE: THREE,
        //   mouseControls: true,
        //   touchControls: true,
        //   gyroControls: false,
        //   minHeight: 600.0,
        //   minWidth: 600.0,
        //   scale: 1.0,
        //   scaleMobile: 1.0
        // })
    //   );
    // }

    
    if (!vantaEffect) {
      initializeVanta();
      setVantaEffect(effect);
    }

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div ref={vantaRef}>
      <p style={{ color: "#fff", paddingTop: "50px" }}>
      </p>
    </div>
  );
};