//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: JSON設定ファイル読込処理
//  Workfile:: code2config.ts
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import * as defif from "../utils/define_interface";
import { makeJsonConfKey, JSON_CONF } from "./jsonconf";
import * as common from "../utils/common";
import * as sstorage from "./SessionStorage";
const systeninfo = require("./conf/systeminfo.json");
const code2conf = require('./conf/code2config.json');
/****************************************************************************/
/*	グローバル変数（static無し）											*/
/****************************************************************************/
// 車系 データ位置 連想配列 
const vUsrbrowsersMap: { [key: string ]: defif.UsrSystemBrowser } = {};

//****************************************************************************
//++module		makedic
//----------------------------------------------------------------------------
//++outline
//				JSON設定ファイル読込処理
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
const makedic = () => {
	/** データの同期的な読み込み. */
	console.log("code2config : build dictionaries");
	try {
		let lsUsr = code2conf["userlist"];
		//code2config.jsonより車系毎定義取得
		lsUsr.forEach((prm: any, index: number) => {
			console.log("makedic:", prm);
			prm["system"].forEach((prm2: any) => {
				vUsrbrowsersMap[prm2["code"]] = {
					sysname: prm2["sysname"],
					configfile: prm2["configfile"],
					fileformat: prm2["fileformat"],
					troublefile: prm2["troublefile"],
					detaillayout: prm2["detaillayout"],
					disp: prm2["disp"]
				};
			});
		});
	} catch (e) {
		console.error(e);
	}
// }

// const getdic = () => {

	// 車系毎フォーマット取得
	try {
		for (let [key, value] of Object.entries(vUsrbrowsersMap)) {
			let jsonkey : string = makeJsonConfKey(value["sysname"], value["configfile"] );
			if( jsonkey === '' ){
				continue;
			}
			let content : any = JSON_CONF[jsonkey];
			if( content === undefined){
				continue;
			}

			let vFormdatainfo: { [key: string]: defif.formdatasrc } = {};
			// jsformatは使用時にJSON.parse
			value["jsformat"] = JSON.stringify(content);
			let jsc = content;

			// ファイルフォーマット読み込み
			let fmtdata = getFileFormatParam( value["configfile"], value["sysname"], value["fileformat"]);
			
			//ファイルフォーマットのheaderサイズ取得(IndexOffset)->  オフセット値
			vUsrbrowsersMap[key].headersize = fmtdata.headersize;

			// カラム―データ割り付け生成
			jsc["clmlink"].forEach((clm: defif.formlistsrc) => {
				// ファイルフォーマットから一致するnameを検索
				for (const key in fmtdata.fileformat) {
					if (clm["name"] === fmtdata.fileformat[key].name) {
						// 一致するnameがある場合は、layout用jsonファイルを取得し、パスを生成
						let conflayoutpath : string = makeJsonConfKey(value["sysname"], fmtdata.fileformat[key].layout || "" );
						if( conflayoutpath === '' ){
							continue;
						}
						// console.log(conflayoutpath, clm["dataname"]);

						//編成状態一覧データ名と割り付け位置の連想配列生成
						vFormdatainfo[clm["dataname"]] = pickDataSrc(
							conflayoutpath,
							clm["dataname"],
							clm["column"],
							fmtdata.fileformat[key].idx || 0
						);
						break;
					}
				}
			});

			vUsrbrowsersMap[key].dataloc = vFormdatainfo;
			vUsrbrowsersMap[key].destlist = pickDestinationJson(
				value["sysname"]
			);
//[xx]		
			//個別域行先 種別
			vUsrbrowsersMap[key].trainkind = pickTrainKind(value["sysname"]);
			vUsrbrowsersMap[key].destination = pickDestination(value["sysname"]);
//[xx]			
		}
	} catch (e) {
		console.error(e);
	}

	//車系毎 共通辞書生成
	try {
		for (let [key, value] of Object.entries(vUsrbrowsersMap)) {

			let jsonkey : string = makeJsonConfKey(value["sysname"], 'common_config.json' );
			if( jsonkey === '' ){
				continue;
			}
			let comconf : any = JSON_CONF[jsonkey];
			if( comconf === undefined){
				continue;
			}
			vUsrbrowsersMap[key].commonconf = pickCommonConfigJson(JSON.stringify(comconf));
		}
	} catch (e) {
		console.error(e);
	}

	// 車系毎故障テーブル取得
	try {
		for (let [key, value] of Object.entries(vUsrbrowsersMap)) {
			let jsonkey : string = makeJsonConfKey(value["sysname"], value["troublefile"] );
			if( jsonkey === '' ){
				continue;
			}
			let content : any = JSON_CONF[jsonkey];
			if( content === undefined){
				continue;
			}

			let devicelist: { [key: number]: string } = {};
			let troubletable: { [key: string]: defif.troubletable } = {};
			let datasetlist: { [key: string]: defif.datasetlist } = {};
			let layoutlist: { [key: string]: defif.layoutlist } = {};
			let jsc = content;

			// 機器名称の連想配列生成
			jsc["devicecode"].forEach((clm: any) => {
				devicelist[clm["code"]] = clm["name"];
			});

			// 故障テーブルの連想配列生成
			jsc["trbTable"].forEach((clm: any) => {
				troubletable[clm["trbcode"]] = pickTrbTable(clm);
			});

			// データセット読み込み
			for(const key in troubletable) {
				let strDatasetName: string = troubletable[key].strDataset || "";
				if((strDatasetName !== "") && (datasetlist[strDatasetName] === undefined)){
					// データセットファイル名が有効、かつ、データセット未登録の場合
					let jsonkey : string = makeJsonConfKey(value["sysname"], strDatasetName );
					if( jsonkey === '' ){
						continue;
					}
					let dataset : any = JSON_CONF[jsonkey];
					if( dataset === undefined){
						continue;
					}
					// データセットの連想配列生成
					datasetlist[strDatasetName] = pickDatasetlist(dataset);
				}
			}

			// レイアウト読み込み
			for(const key in datasetlist) {
				for(const dataname in datasetlist[key]){
					let strLayoutName: string = datasetlist[key][dataname].strLayout || "";
					if((strLayoutName !== "") && (layoutlist[strLayoutName] === undefined)){
						// レイアウトファイル名が有効、かつ、レイアウト未登録の場合
						let jsonkey : string = makeJsonConfKey(value["sysname"], strLayoutName );
						if( jsonkey === '' ){
							continue;
						}
						let layout : any = JSON_CONF[jsonkey];
						if( layout === undefined){
							continue;
						}
						// レイアウトの連想配列生成
						layoutlist[strLayoutName] = pickLayoutlist(layout);
					}
				}
			}

			// ファイルフォーマット読み込み
			let fmtdata = getFileFormatParam( value["configfile"], value["sysname"], value["fileformat"]);
			
			//ファイルフォーマットのheaderサイズ取得(IndexOffset)->  オフセット値
			vUsrbrowsersMap[key].headersize = fmtdata.headersize;

			vUsrbrowsersMap[key].devlist = devicelist;
			vUsrbrowsersMap[key].trbtable = troubletable;
			vUsrbrowsersMap[key].datasetlist = datasetlist;
			vUsrbrowsersMap[key].layoutlist = layoutlist;
			// vUsrbrowsersMap[key].detaillayout = detaillayout;
		}
	} catch (e) {
		console.error(e);
	}

	return vUsrbrowsersMap;
};

//****************************************************************************
//++module		pickCommonConfigJson
//----------------------------------------------------------------------------
//++outline
//				共通情報生成処理
//              車系毎のcommon_config.jsonより
//              アプリ共通辞書を読み込み内部に保持する
//++supp        詳細表示画面にて使用
//
//++end_module
//****************************************************************************
function pickCommonConfigJson(confdir: string): defif.commonconflist {
	// 車系毎共通情報一覧jsonより必要な情報を取得
	let rslt: defif.commonconflist = {};

	try {
		let comm = JSON.parse(confdir);
		//詳細画面 レイアウト取得
		if (comm) {
			rslt.settingCommon = comm["settingCommon"] ? JSON.parse(JSON.stringify(comm["settingCommon"])) : "";
			rslt.settingDetail = comm["settingDetail"] ? JSON.parse(JSON.stringify(comm["settingDetail"])) : "";
			rslt.settingMapView = comm["settingMapView"] ? JSON.parse(JSON.stringify(comm["settingMapView"])) : "";
			rslt.settingTrbPlotView = comm["settingTrbPlotView"] ? JSON.parse(JSON.stringify(comm["settingTrbPlotView"])) : "";
			//車系毎設定 辞書配列 生成
			//要素 "dic"内の全設定取得 
			let dic: defif.statelist = {};
			if (comm["dic"]) {
				let dicarray = JSON.parse(JSON.stringify(comm["dic"]));
				for (let [key, value] of Object.entries(dicarray[0])) {
					dic[key] = JSON.parse(JSON.stringify(value));
				};
				rslt.extstate = dic;
			}
		}


	} catch (e) {
		console.error(e);
	}
	return rslt;
}

//****************************************************************************
//++module		pickDestinationJson
//----------------------------------------------------------------------------
//++outline
//				編成状態一覧 参照元生成
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function pickDestinationJson(systenamne: string): defif.destinationlist {
	// 車系毎の行先一覧JSONより行先コード-名称変換辞書を生成
	let confdestpath : string = makeJsonConfKey(systenamne, "destination.json" );
	let rslt: defif.destinationlist  = {};
	if( confdestpath !== '' ){
		let content = JSON_CONF[confdestpath];
		if(content !== undefined){
//      	let vFormdDestinate: { [key: number]: defif.destinationlist } = {};
			let jsc = content;
			let confjson = JSON.parse(JSON.stringify(jsc["destination"]));
	
			for (let n = 0; n < confjson.length; n++){
				// console.log(confjson[n]);
				//編成状態一覧データ名と割り付け位置の連想配列生成
				let sysds: defif.destination = {dest:confjson[n]["dst"] , kind: confjson[n]["kind"] } ; 
				let cd: number = confjson[n]["code"];
				rslt[cd] = sysds;
			}
		}
    }
	return rslt;
};
//行先一覧
function pickDestination(systenamne: string): defif.destinationlist {
	// 車系毎の行先一覧JSONより行先コード-名称変換辞書を生成
	let confdestpath : string = makeJsonConfKey(systenamne, "destination.json" );
	let rslt: defif.destinationlist  = {};
	if( confdestpath !== '' ){
		let content = JSON_CONF[confdestpath];
		if(content !== undefined){
			try{
	//      	let vFormdDestinate: { [key: number]: defif.destinationlist } = {};
				let jsc = content;
				let confjson = JSON.parse(JSON.stringify(jsc["dest"]));
		
				for (let n = 0; n < confjson.length; n++){
					let cd: number = confjson[n]["code"];
					rslt[cd] =  confjson[n]["name"]
				}

			}catch{
				rslt={};
			}
		}
    }
	return rslt;
};
//種別
function pickTrainKind(systenamne: string): defif.destinationlist {
	// 車系毎の行先一覧JSONより行先コード-名称変換辞書を生成
	let confdestpath : string = makeJsonConfKey(systenamne, "destination.json" );
	let rslt: defif.destinationlist  = {};
	if( confdestpath !== '' ){
		let content = JSON_CONF[confdestpath];
		if(content !== undefined){
			try{
	//      	let vFormdDestinate: { [key: number]: defif.destinationlist } = {};
				let jsc = content;
				let confjson = JSON.parse(JSON.stringify(jsc["kind"]));
		
				for (let n = 0; n < confjson.length; n++){
					let cd: number = confjson[n]["code"];
					rslt[cd] =  confjson[n]["name"]
				}

			}catch{
				rslt={};
			}
		}
    }
	return rslt;
};

//****************************************************************************
//++module		toDictionary
//----------------------------------------------------------------------------
//++outline
//				辞書変換処理
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
export function toDictionary<T extends Record<string, P | object>, P extends PropertyKey, K extends Extract<T[keyof T], PropertyKey>>(items: T[], getKey: (item: T) => K): T[] & Record<K, T> {
    const result = [...items];

    for (const item of items) {
        result[getKey(item) as number] = item;
    }

    return result as T[] & Record<K, T>;
}

// const dictionary = toDictionary([{
//     type: 'list',
//     o: { data: "" },
//     url: 'http://example/list'
// }, {
//     type: 'details',
//     o: { data: "" }, // just to show we can nest objects 
//     url: 'http://example/{0}/details'
// }], x => x.type);

// dictionary.details.url // ok

//****************************************************************************
//++module		pickDataSrc
//----------------------------------------------------------------------------
//++outline
//				編成状態一覧 参照元生成
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function pickDataSrc(path: string, targetname: string, columnname: string, offset: number): defif.formdatasrc {
	// 一覧ＤＢの列Columnにセットする値の参照元をformlist_config.jsonの
	// ”layout"に設定されたjson内から"dataname"の情報を取得し応答する 
	let rslt: defif.formdatasrc = {};

	rslt.column = columnname;
	try {
		let confdata = JSON_CONF[path];
		if(confdata !== undefined){
			let confjs = confdata;
			let confjson = JSON.parse(JSON.stringify(confjs["datasheet"]));

			for (let n = 0; n < confjson.length; n++) {

				if (targetname === confjson[n]["dataname"]) {
					// console.log(confjson[n]["dataname"], targetname, "match");
					rslt.cmnt = confjson[n]["cmt"];
					rslt.dataidx = confjson[n]["idx"] + offset;
					rslt.datasize = confjson[n]["size"];
					rslt.databit = confjson[n]["bit"];
					rslt.trans = confjson[n]["trans"];
					rslt.opt = confjson[n]["opt"];
					break;
				}
			}
		}
	} catch (e) {
		console.error(e);
	}
	return rslt;
};

//****************************************************************************
//++module		getFileFormatParam
//----------------------------------------------------------------------------
//++outline
//				ファイルフォーマットファイル読込
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function getFileFormatParam(dirname: string, sysname: string, fn:string ): defif.stFileFormat {
	// 一覧ＤＢの列Columnにセットする値の参照元をfilefformat.jsonの読み込み
	// 車系毎ファイルフォーマット定義より、必要な情報を取得
	//
	// fileHeadersize: 編成毎最新ファイルのheader部 IndexOffsetとして使用する
	let rslt: defif.stFileFormat = {headersize:4};

	let jsonkey : string = makeJsonConfKey(sysname, fn );
	if( jsonkey !== '' ){
		let confdata = JSON_CONF[jsonkey];
		if( confdata !== undefined){
			let confjs = confdata;
			rslt.headersize = confjs["headersize"];

			// name、idx、layoutを取得
			let format: { [key: string]: defif.onedataformat } = {};
			confjs["format"].forEach((clm: defif.onedataformat, index : number) => {
				if (clm["name"] !== undefined) {
					let name : string = clm["name"];
					format[name] = {
						name : clm["name"],
						idx : clm["idx"],
						layout : clm["layout"],
					};
				}
			});
			rslt.fileformat = format;
		}
	}
	return rslt;
};

//****************************************************************************
//++module		pickTrbTable
//----------------------------------------------------------------------------
//++outline
//				故障テーブル 生成
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function pickTrbTable(clm: any): defif.troubletable {
	// formlist_config.jsonの"troublefile"に設定されたjson内から故障情報を取得 
	let rslt: defif.troubletable = {};

	try {
		rslt.nTrbEnable = clm["trbEnable"];
		rslt.nDevCode = clm["devcode"];
		rslt.nTrbLvl = clm["trblvl"];
		rslt.strDataset = clm["dataset"];
		rslt.strTrbName = clm["trbname"];
	} catch (e) {
		console.error(e);
	}
	return rslt;
};

//****************************************************************************
//++module		pickDatasetlist
//----------------------------------------------------------------------------
//++outline
//				データセット一覧 生成
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function pickDatasetlist(dataset: any): defif.datasetlist {
	// データセットjsonから、データセット一覧を生成 
	let astRslt: { [key: number]: defif.dataset } = {};

	try {
		// データセットjson展開
		let datasetjs = dataset;
		let datasetjson = JSON.parse(JSON.stringify(datasetjs["datasetTable"]));

		for (let n = 0; n < datasetjson.length; n++) {
			let stList: defif.dataset = {};

			// データセット取得
			stList.strDataName = datasetjson[n]["dataname"];
			stList.nIdx = datasetjson[n]["idx"];
			stList.nSize = datasetjson[n]["size"];
			stList.strLayout = datasetjson[n]["layout"];
			stList.strViewtype = datasetjson[n]["viewtype"];
			stList.nMin = datasetjson[n]["min"];
			stList.nMax = datasetjson[n]["max"];
			stList.nCar = datasetjson[n]["car"];

			// データセット一覧に追加
			astRslt[n] = stList;
		}
	} catch (e) {
		console.error(e);
	}
	return astRslt;
};

//****************************************************************************
//++module		pickLayoutlist
//----------------------------------------------------------------------------
//++outline
//				レイアウト一覧生成
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function pickLayoutlist(layout: any): defif.layoutlist {
	// レイアウトjsonから、レイアウト一覧を生成 
	let astRslt: { [key: string]: defif.layoutlist } = {};

	try {
		let layoutjs = layout;
		let layoutjson = JSON.parse(JSON.stringify(layoutjs["datasheet"]));

		for (let n = 0; n < layoutjson.length; n++) {
			let stList: defif.layoutlist = {};

			// レイアウト取得
			stList.nIdx = layoutjson[n]["idx"];
			stList.nSize = layoutjson[n]["size"];
			stList.strTrans = layoutjson[n]["trans"];
			stList.nTransBit = layoutjson[n]["bit"];
			stList.strTransOpt = layoutjson[n]["opt"];
			stList.strLabel = layoutjson[n]["label"];

			// レイアウト一覧に追加
			astRslt[layoutjson[n]["dataname"]] = stList;
		}
	} catch (e) {
		console.error(e);
	}
	return astRslt;
};

//****************************************************************************
//++module		init
//----------------------------------------------------------------------------
//++outline
//				code2config初期化処理
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
// function init() {
// 	console.log("code2conf:", sstorage.getSecureItem(common.SESSION_STORAGE_USER));
// 	let ret;
// 	if ("true" !== sstorage.getSecureItem(common.SESSION_STORAGE_INIT)) {
// 		ret = makedic();
// 		console.log(vUsrbrowsersMap)
// 		sstorage.setSecureItem(common.SESSION_STORAGE_INIT, "true");
// 	} else {
// 		let grp = JSON.parse(JSON.stringify(sstorage.getSecureItem(common.SESSION_STORAGE_USER)));
// 		ret = vUsrbrowsersMap[grp?.stGroup as string];
// 	}

// 	return ret;
// };
function init() {
	let	ret = makedic();

	return ret;
};
function loadsysteminit(){
	const ret = JSON.parse(JSON.stringify(systeninfo));
	return ret;
};
export { init, pickDatasetlist, loadsysteminit };
