//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: エラーページ表示コンテンツ
//  Workfile:: ErrorPage.tsx
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/

/****************************************************************************/
/*	定義値                                          	            					*/
/****************************************************************************/

//****************************************************************************
//++module		Page404
//----------------------------------------------------------------------------
//++outline
//				404ページ表示処理
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
export default function Page404() {

return(  
  <>
    <h1>404 Not Found</h1>
  </>
);
}
