import React, { useState, useEffect} from "react";
import { signInWithEmailAndPassword, onAuthStateChanged ,getIdToken} from "firebase/auth";
import { auth } from "./Firebase";
import { Navigate, Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import * as common from "../../utils/common";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoginIcon from '@mui/icons-material/Login';
import LockResetIcon from '@mui/icons-material/LockReset';
import { setUserName, setUserGroup ,setSessionID} from "../../store/userNameSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "../../store/store";
import * as sstorage  from "../../store/SessionStorage";
import { ReactSVG } from "react-svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { BackgroundVanta } from "../../components/background/Background_vanta"; 
// import "./Login.css";
import logo from "../../asset/logo.png"
/****************************************************************************/
/*	定義値                                          						*/
/****************************************************************************/
// グループごとのURL設定
const TrainMonitorGroup = require('../../store/conf/TrainMonitorGroup.json');

//****************************************************************************
//++module		Login
//----------------------------------------------------------------------------
//++outline
//				ログインページ表示
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
const Login = () => {
	const [loginEmail, setLoginEmail] = useState(""); // ログインメールアドレス
	const [loginPassword, setLoginPassword] = useState(""); // ログインパスワード
	const [user, setUser] = useState<any>(); // ログインユーザー情報
	const [url, setUrl] = useState(""); // URL
	const dispatch = useDispatch();
	const rdxUserName = useSelector((state) => state.userName.strUserName);
	const rdxUserGroup = useSelector((state) => state.userName.strUserGroup);
	const idToken = useSelector((state) => state.userName.strSessionID);

	const [showDialog, setShowDialog] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [isBackgroundVisible, setBackgroundVisible] = useState(true);
	const [isBackgroundVantaVisible, setIsBackgroundVantaVisible] = useState(true);
	const [isVisible, setIsVisible] = useState(false);

	//****************************************************************************
	//++module    handleSubmit
	//----------------------------------------------------------------------------
	//++outline
	//        ボタン処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
		} catch (err) {
			console.log(err);
			let error: any = err;
			let msg = "認証に失敗しました。\r\nメールアドレスまたはパスワードが間違っています";
			// エラーメッセージの表示
			if (error.code === "auth/invalid-email") {
				msg = "メールアドレスが無効です";
			} else if (error.code === "auth/wrong-password") {
				// msg = "パスワードが間違っています";
			} else if (error.code === "auth/user-not-found") {
				// msg = "ユーザーが見つかりません";
			} else if (error.code === "auth/network-request-failed") {
				msg = "ネットワーク接続に問題があります";
			} else if (error.code === "auth/unavailable") {
				msg = "Firebaseサービスに接続できません";
			} else if (error.code === "auth/too-many-requests") {
				msg = "連続した認証リクエストが行われています。時間をおいて再度ログインしてください";
			} else {
				msg = "認証エラーが発生しました";
			}
			alert(msg);
		}
		setShowDialog(false);
	};

	//ダイアログ表示
	const handleSvgClick = () => {
		setShowDialog(true);
	};

	const handleCloseDialog = () => {
		setShowDialog(false);
	};
	const handleMouseEnter = () => {
		setIsHovered(true);
	};
	const handleMouseLeave = () => {
		setIsHovered(false);
	};
	const handleToggleBackground = () => {
		setBackgroundVisible((prevVisible) => !prevVisible);
	};
	//****************************************************************************
	//++module    getGroupUrl
	//----------------------------------------------------------------------------
	//++outline
	//        グループごとURL取得処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
	const getGroupUrl = async (email: string) => {
		let strCollection = TrainMonitorGroup.firestorename; // アクセスするFirestoreのコレクション名
		let astGroupList = TrainMonitorGroup.grouplist; // グループリスト
		let strGroupUrl = ""; // URL
		let strMsg = "";

		try {
			// Firestoreから対象ユーザーのグループ情報を取得
			let strUserGroup = await common.getUserGroup(strCollection, email);
			if (strUserGroup !== undefined) {
				// グループ情報がある場合は、グループリストを検索
				astGroupList.forEach((vGroup: any) => {
					if (vGroup.group === strUserGroup) {
						// 一致するグループのURLを取得
						strGroupUrl = vGroup.TopPage;
						dispatch(setUserGroup(strUserGroup));

						//SessionStorageに現ログイン情報をセット
						const sval = { 
							stUser:email,
							stGroup: strUserGroup,
							stAccessDate: common.formatTimestampToFn()
						};
						//ログイン時 ユーザ属性情報セット、辞書生成
						sstorage.setSecureItem(common.SESSION_STORAGE_USER, JSON.stringify(sval)) ;
						sstorage.setSecureItem(common.SESSION_STORAGE_INIT, "false");

					}
				});

				if (strGroupUrl !== "") {
					// 取得したURLを設定
					setUrl(strGroupUrl);
				} else {
					strMsg = "ユーザーのグループにページが登録されていません";
				}
			} else {
				strMsg = "ユーザーのグループが登録されていません";
			}
		} catch (error) {
			strMsg = "Firestoreに[" + email + "]が登録されていません";
		} finally {
			if (strMsg !== "") {
				await signOut(auth); // ログアウト
				setUrl("");
				dispatch(setUserGroup(""));
				alert(strMsg);
			}
		}
	};

	//****************************************************************************
	//++module    useEffect
	//----------------------------------------------------------------------------
	//++outline
	//        useEffect処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
	useEffect(() => {
		// ログイン判定
		onAuthStateChanged(auth, (currentUser) => {
			setUser(currentUser);
			if (currentUser !== null) {
				getIdToken(currentUser).then(function (idToken: string) {
					dispatch(setSessionID(idToken));
					//Cookie
					fetch(common.SERVER_SIDE_URL + "/api/set-cookie", {
						method: "GET",
						headers: {
							authorization: `${idToken}`
						}
						// credentials: "include" // 追加
					})
						.then((res) => res.json())
						.then((data) => {
							// data内にSet-Cookieヘッダーがあるか確認
							console.log(data);
							document.cookie = data;
						})
						.catch((error) => {
							console.error("Fetch failed", error);
						});
				});
			} else {
				// ユーザー情報が無い場合はURLをクリア
				setUrl("");
				dispatch(setUserGroup(""));
				dispatch(setSessionID(""));
			}
		});
	}, []);

	//****************************************************************************
	//++module    useEffect
	//----------------------------------------------------------------------------
	//++outline
	//        useEffect処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
	useEffect(() => {
		if (user) {
			// ログインユーザーのグループ情報からURLを取得
			getGroupUrl(user.email);
		}
	}, [user]);

	useEffect(() => {
		let resizeTimer: NodeJS.Timeout;
		function handleResize() {
			// サイズが変更された際の処理をここに書く
			setIsVisible(false);

			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				setIsVisible(true);
			}, 900); //20ミリ秒の遅延を加える
		}

		// コンポーネントがマウントされたらイベントリスナーを追加
		window.addEventListener("resize", handleResize);

		// コンポーネントがアンマウントされたらイベントリスナーを削除
		return () => {
			window.removeEventListener("resize", handleResize);
			clearTimeout(resizeTimer); // コンポーネントがアンマウントされた際にタイマーをクリア
		};
	}, []); // [] はマウント時に一度だけ実行することを示す

	useEffect(() => {
		// フェードインのタイミングを設定
		const timer = setTimeout(() => {
			setIsVisible(true);
		}, 1000); // 1000ミリ秒後にフェードインを開始

		return () => clearTimeout(timer); // コンポーネントがアンマウントされる際にタイマーをクリア
	}, []);

	return (
		<>
			{/* ログイン済みの場合は、グループごとに画面を遷移 */}
			{user && url !== "" ? (
				<Navigate to={url} />
			) : (
				<>
					{/* <div className=".full-screen"> */}
					{isVisible && (
						<div
							style={{
								opacity: isVisible ? 1 : 0, // isVisibleがtrueの場合は完全に表示
								transition: "opacity 1s ease-in-out" // フェードインのアニメーションを適用
							}}
						>
							<BackgroundVanta />
						</div>
					)}

					<div>
						<div className="container">
							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
									height: "100vh",
									overflow: "hidden" // スクロールバーを非表示にする
								}}
							>
								<img
									src={common.Icon_Logo_Main}
									alt=""
									onClick={handleSvgClick}
									onMouseEnter={handleMouseEnter}
									onMouseLeave={handleMouseLeave}
									style={{
										// marginLeft: "20px",
										maxWidth: "100%", // 画像の最大幅を100%に設定
										height: "auto", // 高さを自動調整
										opacity: isVisible ? 1 : 0, // isVisibleがtrueの場合は完全に表示
										transition: "opacity 1s ease-in-out" // フェードインのアニメーションを適用
									}}
								/>
							</div>

							<Dialog
								open={showDialog}
								onClose={handleCloseDialog}
								fullWidth={true}
								maxWidth={"lg"}
								sx={{
									// backgroundColor: "#dddddd",
									maxHeight: "auto", // ダイアログの最大高さを画面の70%に設定
									overflowY: "hidden", // 高さが最大値を超えた場合にスクロールさせる
									display: "flex", // 中央配置のためにflexを設定
									alignItems: "center", // 横方向の中央配置
									justifyContent: "center", // 縦方向の中央配置
									padding: "20px", // 上下左右に20pxのpaddingを追加
									margin: "5px", // 上下左右に20pxのmarginを追加
									position: "fixed"

									// position: "absolute", // fixedからabsoluteに変更
									// ダイアログを固定位置に配置
									// left: "50%",
									// top: "10%" // 上端を画面の中央に
									// transform: "translate(-50%, -50%)" // 中央配置に変更
								}}
							>
								<DialogTitle
									sx={{
										backgroundColor: "#1976D2", // 背景色
										color: "#ffffff", // 文字色を白色に
										textAlign: "center", // テキストを中央に配置
										padding: "5px", // 上下左右に10pxのpaddingを追加
										margin: "0" // 上下左右のmarginを0に設定
									}}
								>
									ユーザログイン
								</DialogTitle>
								<DialogContent>
									<br />
									<form onSubmit={handleSubmit}>
										<div>
											<DialogContentText>メールアドレス</DialogContentText>
											<div style={{ height: "40px" }}>
												<TextField
													className="inputlogin"
													name="email"
													type="email"
													value={loginEmail}
													autoComplete="off"
													onChange={(e) => setLoginEmail(e.target.value)}
													// InputProps={{
													// 	sx: {
													// 		height: "40px", // 入力フィールドの高さを30pxに設定
													// 		//margin: "0"  上下左右のmarginを0に設定
													// 	}
													// }}
												/>
											</div>
										</div>
										<div>
											<DialogContentText>パスワード</DialogContentText>
											<div style={{ height: "40px" }}>
												<TextField
													className="inputlogin"
													name="password"
													type="password"
													value={loginPassword}
													autoComplete="off"
													onChange={(e) => setLoginPassword(e.target.value)}
													// InputProps={{
													// 	sx: {
													// 		height: "40px", // 入力フィールドの高さを30pxに設定
													// 		margin: "0" // 上下左右のmarginを0に設定
													// 	}
													// }}
												/>
											</div>
										</div>
										<Button variant="contained" type="submit" startIcon={<LoginIcon />}>
											ログイン
										</Button>
									</form>
									<br />
									{/* ↓リンクを追加 */}
									<div style={{ textAlign: "center" }}>
										<Button
											variant="outlined"
											component={Link}
											to={common.PAGE_PASSWORDRESET}
											startIcon={<LockResetIcon />}
										>
											パスワード再設定
										</Button>
									</div>
								</DialogContent>
							</Dialog>
						</div>
					</div>
					{/* testcopde */}

					{/* <Button
						className="toggle-button"
						variant="contained"
						onClick={handleToggleBackground}
						sx={{
							color: "#ffffff" // 文字色を白色に
						}}
					>
						{isBackgroundVisible ? "Hide Background" : "Show Background"}
					</Button> */}

					{/* </div> */}
				</>
			)}
		</>
	);
};

export default Login;     
