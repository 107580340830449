//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: 共通定義
//  Workfile:: common.tsx
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************
/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import { db } from "../View/Login/Firebase";
import { doc, getDoc } from "firebase/firestore";
import Zlib from "zlib";
import lodash from "lodash";
import { ObjectBindingPattern } from "typescript";
import Icon_Logo_Main from "../asset/logo.png";
import Icon_Logo_Black from "../asset/logo_black.png";
// import Icon_Logo_Main from "../asset/logo.svg";
// import Icon_Logo_Black from "../asset/logo_black.svg";

/********************************************/
/*	定義値                                  */
/********************************************/
const FLG_DEMO = false;	// デモフラグ
//
const UPDATE_CYCLE = 1000;               //画面更新値
const AUTO_LOGOUT_TIME = 3600 * 1000;	// 自動ログアウト時間(msec)

const THREEMONTH_TIMER = 3 * 30 * 24 * 60 * 60 * 1000; // Assuming 30 days per month

// const REQ_TIMEOUT = 2000;                // fetchタイムアウト時間(msec)
const REQ_TIMEOUT = 1000;                // fetchタイムアウト時間(msec)
const RETRY_MAX = 5;				    // Firestoreアクセスリトライ回数
const RETRY_CYCLE = 200;			    // Firestoreアクセス周期(msec)
const SERVER_SIDE_PORT = ":8080";       // ポート
// グループごとのURL設定
const TrainMonitorGroup = require('../store/conf/TrainMonitorGroup.json');
const PAGE_LOGIN = "/";
const PAGE_REGISTER = "/register/";
const PAGE_PASSWORDRESET = "/PasswordReset";
const PAGE_FORMLIST = "/FormList";
const PAGE_FORMDATAPLOT = "/FormDataPlot";
const PAGE_FORM_MAP = "/FormListMap";
const PAGE_TRBNOW_LIST = "/TrbNowList";
const PAGE_TRB_LIST = "/TrbAllList";
const PAGE_CARSTATE = "/FormDetail";
const PAGE_DATA_PICK = "/FormDataPicker";
const PAGE_PICK_DATAPLOT = "/FormPickDataPlot";
const FILE_EXT_GUNZIP = ".gz";

const PAGE_TITLE_REALTIME = "リアルタイム車両状態";
const PAGE_TITLE_TRBANALYZE = "故障解析";
const PAGE_TITLE_DATAPICK = "任意項目抽出"; //Ver.A2
//対node-server API IF
const REQ_GETCARDATA_FORMORDER = "/api/getCarDataFormOrder?"
const REQ_GETCONF_FORMORDER = "/api/getConfFormOrder?";
const REQ_GETGZFILE_FORMORDER = "/api/getgzFile?";
const REQ_GET_GPSPOS = "/api/getGpsposdb";

const REQ_DATAPICK_LIST = "/api/datapicklist"; //Select/Insert
const REQ_DATAPICK_LIST_ID = "/api/datapicklist/:id"; //Update/Delete
const REQ_GETPICKGZFILE = "/api/getdatapickgzFile?";
const SERVER_SIDE_URL = getAccessURL() + SERVER_SIDE_PORT; //https

//ステータスアイコン更新タイマ 
const DATA_DISABLE_TIMER = (FLG_DEMO) ? 120 : 60;
const CAR_STATE_DATA_DISABLE_1min = 80;
//const CAR_STATE_DATA_DISABLE_1min = DATA_DISABLE_TIMER;
const CAR_STATE_GPS_CHECKTIMER = 3000;
// const CAR_STATE_DATA_DISABLE_1min = 100; //DEMO

//データ抽出ステータス
const ST_DATA_COMPLETE = 'C';
const ST_DATA_REGISTER = 'R';
const ST_DATA_PROCESSING = 'P';
const ST_DATA_EVACUTE = 'E';


// Config キー名
const SESSION_STORAGE_USER = "key_user";
const SESSION_STORAGE_TARGETFILE = "key_targetfile";
const SESSION_STORAGE_SESSIONID = "strSessionID";
const SESSION_STORAGE_INIT = "key_Init";
// Config キー名
const CONF_KEY_VIEWNCONF = "viewconfig";
const CONF_KEY_DETAILLAYOUT = "detaillayout";
const CONF_KEY_SYSNAME = "sysname";


/********************************************/
/*	構造体定義                              */
/********************************************/

/********************************************/
/*	共通関数                                */
/********************************************/
let strCurrentUserGroup = ""; //ログインユーザーのグループ
//****************************************************************************
//++module		utf8ArrayToStr
//----------------------------------------------------------------------------
//++outline
//				UTF-8配列をstring型に変換
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function utf8ArrayToStr(vArray:any) {
let nLen:number = vArray.length;
let strOut:string = "";
let nCnt:number = 0;
var vChar1, vChar2, vChar3;

    while (nCnt < nLen) {
        vChar1 = vArray[nCnt++];
        switch (vChar1 >> 4) {
            case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                strOut += String.fromCharCode(vChar1);
                break;
            case 12: case 13:
                vChar2 = vArray[nCnt++];
                strOut += String.fromCharCode(((vChar1 & 0x1F) << 6) | (vChar2 & 0x3F));
                break;
            case 14:
                vChar2 = vArray[nCnt++];
                vChar3 = vArray[nCnt++];
                strOut += String.fromCharCode(((vChar1 & 0x0F) << 12) |
                    ((vChar2 & 0x3F) << 6) |
                    ((vChar3 & 0x3F) << 0));
                break;
        }
    }
    return strOut;
}

//****************************************************************************
//++module		HexStrToutf8Array
//----------------------------------------------------------------------------
//++outline
//				16進数文字列をUTF-8配列に変換
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function HexStrToutf8Array(strHex:any) {
    let str:any = strHex.slice(2);
    let abRet:Uint8Array = new Uint8Array(str.length / 2);
    let i:number = 0;

    for(let nCnt:number = 0; nCnt < str.length; nCnt+=2) {
        let num = str[nCnt] + str[nCnt + 1];
        let a = parseInt(num, 16);
        abRet[i++] = a;
    }

    return abRet;
}

//****************************************************************************
//++module		changeUint16
//----------------------------------------------------------------------------
//++outline
//				数値を16bitのunsigned intに変換
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function changeUint16(nSrcCode:number):number {
    let nDstCode: number;
  
    // 故障コードをunsigned intに変換
    let temp = nSrcCode>>>0;
    // unsigned intの値を32bitから16bitに変換
    nDstCode = temp & 0x0000FFFF;
  
    return nDstCode;
}

//****************************************************************************
//++module		HexStrToutf8Array
//----------------------------------------------------------------------------
//++outline
//				16進数文字列を数値に変換
//****************************************************************************
function tryParseInt(value: string): number | undefined {
	const parsedValue = parseInt(value,16);
	if (isNaN(parsedValue)) {
		return undefined;
	}
	return parsedValue;
}

//****************************************************************************
//++module		sleep
//----------------------------------------------------------------------------
//++outline
//				sleep処理
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function sleep(ms:number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

//****************************************************************************
//++module		getUserGroup
//----------------------------------------------------------------------------
//++outline
//				Firestoreからユーザーのグループを取得
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
async function getUserGroup(strCollection:string, strEmail:string) {
    let strGroup = undefined;

    // Firestoreに反映されるまで時間が掛かるため
    // リトライ処理を追加
    for(let nCnt = 0; nCnt < RETRY_MAX; nCnt++){
        // Firestoreから対象ユーザーのグループ情報を取得
        let res = await getDoc(doc(db, strCollection, strEmail));
        if(res.exists()){
            // グループ情報からグループ名を取り出す
            let vUserData = res.data();
            strGroup = vUserData.group || undefined;
            break;
        }

        // Firestoreに反映されるまで待機
        await sleep(RETRY_CYCLE);
    }

    return strGroup;    
}

//****************************************************************************
//++module		checkGroupPageList
//----------------------------------------------------------------------------
//++outline
//				ログインしたユーザーのグループに要求ページが登録されているかを判定
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
async function checkGroupPageList(strEmail:string, strPage:string){
    let strCollection = TrainMonitorGroup.firestorename;	// アクセスするFiresoreのコレクション名
    let astGroupList = TrainMonitorGroup.grouplist;		    // グループリスト
    let blRet = false;

    try{
        // Firestoreから対象ユーザーのグループ情報を取得
        let strUserGroup = await getUserGroup(strCollection, strEmail);
        if(strUserGroup !== undefined) {
            // グループリストを検索
            astGroupList.forEach( (vGroup:any) => {
                if(vGroup.group === strUserGroup) {
                    // グループ名が一致するグループ情報のページリストを検索
                    vGroup.PageList.forEach((vUrl:any) => {
                        if(vUrl.url === strPage){
                            // 該当ページが登録されている場合
                            blRet = true;
                            //vConfigセット
                            if (strCurrentUserGroup !== strUserGroup) { 
                                
                            }
                            //ユーザグループ登録
                            strCurrentUserGroup = (strUserGroup);
                        }                            
                    });
                }
            });
        }
    } catch(error) {
        blRet = false;
        strCurrentUserGroup = "";
    }
    return blRet;
}

function getCurrentUserGroup() {
	return strCurrentUserGroup;
}


//****************************************************************************
//++module		getServerSideURL
//----------------------------------------------------------------------------
//++outline
//				アクセスされたURLを返却
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function getAccessURL(){
    let strUrl = "";
    let vlocation = new URL(window.location.href);

    //strUrl = vlocation.protocol + "//" + "192.168.62.1";
    strUrl = vlocation.protocol + "//" + vlocation.hostname;

    return strUrl;
}
//デフォルトエラー
function defaultError(data: any)
{
	console.log(data);
}
//****************************************************************************
//++module		connectBinaryArray
//----------------------------------------------------------------------------
//++outline
//				serverSideバイナリーデータ取得処理
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
async function connectBinaryArray(
	type: object,
	// type: string,
	actionPath: string,
	successCallBack: Function,
    errorCallBack: Function,
) {

	await fetch(actionPath, type)
	// await fetch(actionPath, { method: type })
		.then((res) => res.blob())
		.then(
			(vResult) => {
				successCallBack(vResult);
			},
			(error) => {
				errorCallBack(error);
			}
		);
}
//****************************************************************************
//++module		connectBinaryArray
//----------------------------------------------------------------------------
//++outline
//****************************************************************************
async function getGzJson(strFileName: string, callFunc: Function, 	param: object) {
    //非同期で取得したデータを、指定の関数で処理
    await connectBinaryArray(param, strFileName, callFunc, defaultError);
}
//****************************************************************************
//++module		unzipGzFile
//----------------------------------------------------------------------------
//++outline
//				Gz読み込み展開処理
//++end_module
//****************************************************************************
async function unzipGzFile(bResultData: Blob , setFunc?: Function) {
    // async function unzipGzFile(bResultData: Blob) {
    try {
        return new Promise((resolve) => {
            var zipReader = new FileReader();
            zipReader.onload = function () {
                try {
                    if (zipReader.result instanceof ArrayBuffer) {
                        //Geojson.gz展開
                        var vZipArr = new Uint8Array(zipReader.result as ArrayBuffer);
                        var vGunzip = Zlib.gunzipSync(Buffer.from(vZipArr));
                        var vJsonBuffer = utf8ArrayToStr(vGunzip);
                        var vGjTemp = null; // GeoJson一時バッファ

                        try {
                            vGjTemp = vJsonBuffer;
                            // vGjTemp = JSON.parse(vJsonBuffer);

                            if (undefined !== setFunc) {
                                setFunc(vGjTemp);
                            }
                        } catch (e) {
                            console.log("JSON syntax is incorrect." + { e });
                            return;
                        } finally {
                            resolve(vGjTemp);
                        }
                    } else {
                        console.log("Failed to get ArrayBuffer from FileReader result.");
                        resolve(null);
                    }
                } catch (e) {
                    console.log(e);
                    resolve(null); // エラーが発生した場合も resolve する必要があります
                }
                // finally {
                // 	//詳細テーブル定義設定
                // 	return vGjTemp;
                // }
            };
            zipReader.readAsArrayBuffer(bResultData);
        });
    } catch (e) { 
        console.log(e);
        return null;
    }
}
//****************************************************************************
// 全角半角数値変換
// function exChangeHalfNum(text: string | number) {
function replaceHalfToFull(str:String) {
	return str.replace(/[!-~]/g, function (s) {
		return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
	});
}
function replaceFullToHalf(str:String){
  return str.replace(/[！-～]/g, function(s){
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}


///////////////////////////////////////////
// arCarBitCheck
// 故障発生・解除車両の応答
// 数値配列
//
function arCarBitCheck(trbcar: string): number[] {
    // 故障発生と故障解除のビットを取得
    const bTrbCar: string = trbcar.split("").reverse().join("");
    // const bTrbCar: number = Number(trbcar);

    // 発生した故障の号車を格納する配列
    const rslt: number[] = [];
  
    // 故障発生のビットを解析して発生した号車を特定
    // if (bTrbCar > 0) {
    //   for (let i = 0; i < bTrbCar.toString().length +1; i++) {
    //     if (bTrbCar & (1 << i)) {
    //       rslt.push(i + 1);
    //     }
    //   }
    // }
    if (trbcar.length > 0) {
		for (let i = 0; i < bTrbCar.toString().length ; i++) {
			if (bTrbCar[i] === "1") {
				rslt.push(i + 1);
			}
		}
    }
    // console.log(rslt)
    return rslt;
}

  ///////////////////////////////
  //数字配列を セパレータで結合した文字列で応答
function convertToStringWithSeparator(numbers: number[], strSep: string): string {
	const stringArray = numbers.map((number) => number.toString());
	return stringArray.join(strSep);
}

//タイムスタンプ 整形
function formatTimestampToFn(timestamp?: number): string {
	const date = (timestamp === undefined)?  new Date(): new Date(timestamp); // タイムスタンプをミリ秒に変換するために1000倍する
	// const year = date.getFullYear().slice(-2).padStart(2, "0");
	const year = String(date.getFullYear()).slice(-2).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	// const milliseconds = String(date.getMilliseconds()).padStart(1, "0");

	return `${year}${month}${day}${hours}${minutes}${seconds}`;
}
  
//ランダムのカラーコード生成
// function generateRandomColorCode(): string {
// 	const characters = '0123456789ABCDEF';
// 	let colorCode = '#';
	
// 	for (let i = 0; i < 6; i++) {
// 		colorCode += characters[Math.floor(Math.random() * 16)];
// 	}
// 	return colorCode;
// }
//ランダムのカラーコード生成
// 指定値以上の明度
// function generateRandomColorCode(): string {
// //   const characters = '0123456789ABCDEF';
//   let colorCode = '#';
  
//   const maxBrightness = 150; // 明るさの最大値 (0-255の範囲)
//   let isValidColor = false;

//   while (!isValidColor) {
//     colorCode = '#';
//     for (let i = 0; i < 3; i++) {
//       const randomValue = Math.floor(Math.random() * 256);
//       colorCode += randomValue.toString(16).padStart(2, '0');
//     }

//     // Calculate brightness (based on HSP color model)
//     const r = parseInt(colorCode.substring(1, 2), 16);
//     const g = parseInt(colorCode.substring(3, 2), 16);
//     const b = parseInt(colorCode.substring(5, 2), 16);
//     const brightness = Math.sqrt(0.299 * r * r + 0.587 * g * g + 0.114 * b * b);

//     if (brightness <= maxBrightness) {
//       isValidColor = true;
//     }
//   }

//   return colorCode;
// }

function generateRandomColorCode(minBrightness: number = 150): string {
    const generateRandomValue = (): number => Math.floor(Math.random() * 256);

    let colorCode = '#';
    let isValidColor = false;

    while (!isValidColor) {
        colorCode = '#';

        // Generate random RGB values
        const r = generateRandomValue();
        const g = generateRandomValue();
        const b = generateRandomValue();

        // Calculate brightness (HSP color model)
        const brightness = Math.sqrt(0.299 * r * r + 0.587 * g * g + 0.114 * b * b);

        // Check if the brightness meets the minimum requirement
        if (brightness <= minBrightness) {
            isValidColor = true;
        }

        // Convert RGB values to hexadecimal and pad with zeros
        colorCode += r.toString(16).padStart(2, '0');
        colorCode += g.toString(16).padStart(2, '0');
        colorCode += b.toString(16).padStart(2, '0');
    }

    return colorCode;
}

function removeLastComma(inputString: string): string {
	// 文字列末尾から最初に出現する `,` を正規表現で検索し、削除する
	return inputString.replace(/,\s*$/, "");
}
//マウスオーバーイベント
//カーソル変更
const handleCellMouseEnter = (event:any) => {
	event.target.style.cursor = "pointer";
  };
//マウスハズレイベント
const handleCellMouseLeave =(event:any) => {
	event.target.style.cursor = "default";
};

function createNewRow(dt:any) {
	const parsedData = JSON.parse(dt);

	const newRow = {
		id: parsedData.treeid || "1",
		name: parsedData.gridtableitem || "-",
		color: generateRandomColorCode() || "#000000",
		linetype: "solid",
		linetwidth: "1",
		dataname: parsedData.dataname || "",
		categoly: parsedData.categoly || "common",
		device: parsedData.device || "common",
		car: parsedData.car || "1"
	};

	return newRow;
}

//debug -snack -Alert item
// 呼び出し先でas AlertColor する
function getRandomSnackStatus(): string {
  const statuses = ["error", "warning", "info", "success"];
  const randomIndex = Math.floor(Math.random() * statuses.length);
  return statuses[randomIndex];
}

//****************************************************************************
// DownSampling 
//定点ダウンサンプル
function getDownSampleEven(src: any[], div: number): number[] {
    const result = lodash.chunk(src, div).map(chunk => chunk[0]);
    return result;
}
//分割無い最大値
function getDownSampleLTTB(src: any[], div: number, max :boolean = true): number[] {
	const result = lodash.chunk(src, div).map((chunk) => chunk[0]);
	return result;
}

//****************************************************************************
// Export
export {AUTO_LOGOUT_TIME};
export {REQ_TIMEOUT};
export {RETRY_MAX};
export {RETRY_CYCLE};
export {SERVER_SIDE_PORT};
export {PAGE_LOGIN};
export {PAGE_REGISTER};
export {PAGE_PASSWORDRESET};
export {PAGE_FORMLIST};
export {PAGE_FORMDATAPLOT};
export {PAGE_FORM_MAP };
export { PAGE_TRBNOW_LIST };
export { PAGE_TRB_LIST };
export { REQ_GET_GPSPOS };
export { PAGE_CARSTATE };
export { FILE_EXT_GUNZIP };
export { UPDATE_CYCLE };
export {utf8ArrayToStr};
export {HexStrToutf8Array};
export {changeUint16};
export {sleep};
export {getUserGroup};
export {checkGroupPageList};
export {getAccessURL};
export {getCurrentUserGroup};
export { tryParseInt };
export { connectBinaryArray };
export { getGzJson };
export { unzipGzFile };
export { defaultError };
export { replaceHalfToFull };
export { generateRandomColorCode };
export { replaceFullToHalf };
export { PAGE_TITLE_REALTIME };
export { PAGE_TITLE_TRBANALYZE };
export { REQ_GETCARDATA_FORMORDER };
export { REQ_GETCONF_FORMORDER};
export { REQ_GETGZFILE_FORMORDER };
export { CAR_STATE_GPS_CHECKTIMER };
export { CAR_STATE_DATA_DISABLE_1min };
export { SERVER_SIDE_URL };
export { handleCellMouseLeave };
export { handleCellMouseEnter };
export { convertToStringWithSeparator };
export { arCarBitCheck };
export { Icon_Logo_Black };
export { Icon_Logo_Main };
export { createNewRow };
export { FLG_DEMO };
export { PAGE_DATA_PICK };
export { PAGE_TITLE_DATAPICK };
export { ST_DATA_COMPLETE };
export { ST_DATA_REGISTER };
export { ST_DATA_PROCESSING };
export { ST_DATA_EVACUTE };
    
export { REQ_DATAPICK_LIST };
export { REQ_DATAPICK_LIST_ID };
export { CONF_KEY_VIEWNCONF };
export { CONF_KEY_DETAILLAYOUT };
export { CONF_KEY_SYSNAME };
export { removeLastComma };
export { THREEMONTH_TIMER };
export { getRandomSnackStatus };
export { PAGE_PICK_DATAPLOT };
export { REQ_GETPICKGZFILE };
export { formatTimestampToFn };
export { SESSION_STORAGE_USER };
export { SESSION_STORAGE_TARGETFILE };
export { SESSION_STORAGE_INIT };

