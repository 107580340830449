//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: JSON設定ファイルSlice
//  Workfile:: configJsonSlice.ts
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import { createSlice } from '@reduxjs/toolkit';
const code2conf = require("./code2conf");

/****************************************************************************/
/*	定義値                                          												*/
/****************************************************************************/
export const configJsonSlice = createSlice({
	name: "configJson",
	initialState: {
		vConfigJson: code2conf.init(),
		// strSystemFrontInformation:"", //FrontEnd SytemVersion
		strSystemFrontInformation: code2conf.loadsysteminit(), //FrontEnd SytemVersion
		strSystemBackInformation: "", //Backend SytemVersion
		strSystemDBInformation: "" //DB SytemVersion
	},
	reducers: {
		setConfigJson: (state, action) => {
			state.vConfigJson = action.payload;
		},
		setSystemFrontInformation: (state, action) => {
			state.strSystemFrontInformation = action.payload;
		},
		setSystemBackInformation: (state, action) => {
			state.strSystemBackInformation = action.payload;
		},
		setSystemDBInformation: (state, action) => {
			state.strSystemDBInformation = action.payload;
		}
	}
});

export const { setConfigJson } = configJsonSlice.actions;
export const { setSystemFrontInformation } = configJsonSlice.actions;
export const { setSystemBackInformation } = configJsonSlice.actions;
export const { setSystemDBInformation } = configJsonSlice.actions;

export default configJsonSlice.reducer;
