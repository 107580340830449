//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: 新規登録ページ表示
//  Workfile:: Register.js
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								*/
/****************************************************************************/
import React, { useState ,useEffect, useRef } from "react";
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase";
import { doc, setDoc } from "firebase/firestore";
import { Navigate, Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import * as common from "../../utils/common";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LoginIcon from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
/****************************************************************************/
/*	定義値                                          						*/
/****************************************************************************/
// グループごとのURL設定
const TrainMonitorGroup = require('../../store/conf/TrainMonitorGroup.json');
const strCollection = TrainMonitorGroup.firestorename;		// アクセスするFiresoreのコレクション名
const astGroupList = TrainMonitorGroup.grouplist;			// グループリスト
const GROUP_CHECK = 0;										// グループのページリスト検索中
const GROUP_UNREGISTERED = 1;								// グループのページリスト未登録
const GROUP_REGISTERED = 2;									// グループのページリスト登録済み

//****************************************************************************
//++module		Register
//----------------------------------------------------------------------------
//++outline
//				新規登録ページ表示
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
const Register = () => {
	const [registerEmail, setRegisterEmail] = useState("");			// 登録メールアドレス
	const [registerGroup, setRegisterGroup] = useState("");			// 登録グループ
	const [registerPassword, setRegisterPassword] = useState("");	// 登録パスワード
	const [user, setUser] = useState();								// 登録ユーザー情報
	const [url, setUrl] = useState("");								// URL
	const [loading, setLoading] = useState(true);
	const [nReqPage, setReqPage] = useState(GROUP_CHECK);

	//****************************************************************************
	//++module		handleSubmit
	//----------------------------------------------------------------------------
	//++outline
	//				ボタン処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
			let docRef = doc(db, strCollection, registerEmail);
			let data = {
				group: registerGroup,
			}
			setDoc(docRef, data);
			alert("登録が完了しました");
		} catch (error) {
			alert("正しく入力してください");
		}
	};

	//****************************************************************************
	//++module		logout
	//----------------------------------------------------------------------------
	//++outline
	//				ログアウト処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
	const logout = async () => {
		await signOut(auth);  // ログアウト
	};

//debug 新規登録画面の仕様が決まるまで、編成一覧へのリンクを表示する。
	const toFormList = async () => {
		setUrl(common.PAGE_FORMLIST);
	};

	const blDidEffect = useRef(false);    // useEffect初回フラグ
	//****************************************************************************
	//++module		useEffect
	//----------------------------------------------------------------------------
	//++outline
	//				useEffect処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
	useEffect(() => {
		if( !blDidEffect.current ){
			blDidEffect.current = true;
			// 自動ログアウト時間を設定
			// 一定時間、画面遷移が無い場合は自動でログアウトする
//DEMO  DEL -->			
		// 	const logouttimer = setTimeout(() => {
		// 		clearTimeout(logouttimer);
		// 		// ログアウト
		// 		logout();
		// 	}, common.AUTO_LOGOUT_TIME);
//DEMO  DEL -->
		}
		// ログイン判定
		onAuthStateChanged(auth, (currentUser) => {
			setUser(currentUser);
			setLoading(false);
		});
	}, []);
	
	//****************************************************************************
	//++module		useEffect
	//----------------------------------------------------------------------------
	//++outline
	//				useEffect処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
	useEffect(() => {
		//****************************************************************************
		//++module		getGroupUrl
		//----------------------------------------------------------------------------
		//++outline
		//				グループごとURL取得処理
		//++arguments
		//
		//++supp
		//
		//++end_module
		//****************************************************************************
		const getGroupUrl = (async (email: string) => {
			let strGroupUrl = "";									// URL
			let strMsg = "";
	
			try{
				// Firestoreから対象ユーザーのグループ情報を取得
				let strUserGroup = await common.getUserGroup(strCollection, email);
				if(strUserGroup !== undefined) {
					// グループ情報がある場合は、グループリストを検索
					astGroupList.forEach( (vGroup:string) => {
						if(vGroup.group === strUserGroup) {
							// 一致するグループのURLを取得
							strGroupUrl = vGroup.TopPage;
						}
					});
		
					if(strGroupUrl !== "") {
						if(strGroupUrl !== common.PAGE_REGISTER){
							// 取得したURLが新規登録画面でなければURLを設定
							setUrl(strGroupUrl);
						}
						// 要求ページ判定
						await checkReqPage(email, common.PAGE_REGISTER);
					} else {
						strMsg = "指定したグループにページが登録されていません";
					}
				} else {
					strMsg = "指定したグループが登録されていません";
				}
			} catch(error) {
				strMsg = "Firestoreに[" + email + "]が登録されていません";
			} finally {
				if(strMsg !== "") {
					alert(strMsg);
					setUrl("");
					await signOut(auth);  // ログアウト
				}
			}
		});

		//****************************************************************************
		//++module		checkReqPage
		//----------------------------------------------------------------------------
		//++outline
		//				要求ページ判定処理
		//++arguments
		//
		//++supp
		//
		//++end_module
		//****************************************************************************
		async function checkReqPage(strUser, strPath){
			// ログインしたユーザーのグループに、要求ページが登録されているかを判定する
			let res = await common.checkGroupPageList(strUser, strPath);
			if(res){
				setReqPage(GROUP_REGISTERED);
			} else {
				setReqPage(GROUP_UNREGISTERED);
			}
		}

		if(user){
			// ログインユーザーのグループ情報からURLを取得
			getGroupUrl(user.email);
		}
	}, [user]);

	return (
		<>
			{!loading && (
				<>
					{/* ログインしていない場合はログインページにリダイレクト */}
					{!user ? (
						<Navigate to={common.PAGE_LOGIN} />
					) : (
						<>
							{/* 別グループにアクセスした場合は404ページを表示 */}
							{nReqPage !== GROUP_CHECK && (
								<>
									{nReqPage === GROUP_UNREGISTERED ? (
										<h1>404 Not Found</h1>
									) : (
										<>
											{/* ログイン済みの場合は、グループごとに画面を遷移 */}
											{user && url !== "" ? (
												<Navigate to={url} />
											) : (
												<>
													<div className="container">
														<h1>新規登録</h1>
														<form onSubmit={handleSubmit}>
															<div>
																<label>メールアドレス</label>
																{/* <input
																	name="email"
																	type="email"
																	value={registerEmail}
																	onChange={(e) => setRegisterEmail(e.target.value)}
																/> */}
																<TextField
																	className="inputlogin"
																	name="email"
																	type="email"
																	autoComplete="off"
																	value={registerEmail}
																	onChange={(e) => setRegisterEmail(e.target.value)}
																/>
															</div>
															<div>
																<label>グループ</label>
																{/* <input
																	name="group"
																	type="group"
																	value={registerGroup}
																	onChange={(e) => setRegisterGroup(e.target.value)}
																/> */}
																<TextField
																	className="inputlogin"
																	name="group"
																	type="group"
																	autoComplete="off"
																	value={registerGroup}
																	onChange={(e) => setRegisterGroup(e.target.value)}
																/>
															</div>
															<div>
																<label>パスワード</label>
																<TextField
																	className="inputlogin"
																	name="password"
																	type="password"
																	autoComplete="off"
																	value={registerPassword}
																	onChange={(e) =>
																		setRegisterPassword(e.target.value)
																	}
																/>
															</div>
															{/* <button>登録する</button> */}
															<Button
																variant="contained"
																onClick={handleSubmit}
																type="submit"
																startIcon={<AssignmentTurnedInIcon />}
															>
																登録する
															</Button>
															{/* ↓リンクを追加 */}
															<p>
																{/* ログインは
																<Link to={common.PAGE_LOGIN} onClick={logout}>
																	こちら
																</Link> */}
																<div style={{ textAlign: "center" }}>
																	<Button
																		variant="outlined"
																		component={Link}
																		onClick={logout}
																		startIcon={<Logout />}
																	>
																		ログアウト
																	</Button>
																</div>
															</p>
															<br />
															{/* debug 新規登録画面の仕様が決まるまで、編成一覧へのリンクを表示する。*/}
															{/* <button onClick={toFormList}>編成一覧</button> */}
															<Button
																variant="contained"
																onClick={toFormList}
																type="submit"
																startIcon={<LoginIcon />}
															>
																編成一覧
															</Button>
														</form>
													</div>
												</>
											)}
										</>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default Register;