// secureStorage.ts
export const setSecureItem = (key: any, value: any) => {
	try {
		sessionStorage.setItem(key, value);
	} catch (error) {
		// エラーハンドリング
		console.log("setSecureItem:",error);
	}
};

export const getSecureItem = (key:any) => {
	try {
		return sessionStorage.getItem(key);
	} catch (error) {
		// エラーハンドリング
		console.log("getSecureItem:", error);
	}
};

export const removeSecureItem = (key:any) => {
	try {
		sessionStorage.removeItem(key);
	} catch (error) {
		// エラーハンドリング
		console.log("removeSecureItem:", error);
	}
};
// Redux 保存（同ページリロード対応）
// sessionStorageから状態を読み込む
export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('reduxState');
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (err) {
    return undefined; // 読み込み失敗時はundefinedを返す
  }
};

// 状態をsessionStorageに保存する
export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('reduxState', serializedState);
  } catch (err) {
    // エラーハンドリング
  }
};
