//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: アプリケーションルートソースファイル
//  Workfile:: App.tsx
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import routes from './routes'
import CssBaseline from '@mui/material/CssBaseline';
import Register from "./View/Login/Register.js";
import Login from "./View/Login/Login";
import PasswordReset from "./View/Login/PasswordReset";
import UserAction from "./UserAction";
import ErrorPage from "./View/common/ErrorPage";
/****************************************************************************/
/*	定義値                                          												*/
/****************************************************************************/
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const mdTheme = createTheme();

//****************************************************************************
//++module		App
//----------------------------------------------------------------------------
//++outline
//				アプリケーションクラス
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
const App = () => {

//   const [isContentLoaded, setIsContentLoaded] = useState(false);

//   useEffect(() => {
//     // コンテンツが読み込まれたら非表示を解除
//     setIsContentLoaded(true);
//   }, []);

  return (
		<ThemeProvider theme={mdTheme}>
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<BrowserRouter>
					<Routes>
						<Route path={`/`} element={<Login />} />
						<Route path={`/register/`} element={<Register />} />
						<Route path={`/PasswordReset/`} element={<PasswordReset />} />
						<Route path={`/UserAction/`} element={<UserAction />} />
						<Route path='/' element={<DefaultLayout />} >
						{/* {isContentLoaded && (
							<Route path="/" element={<DefaultLayout />}> */}
								{routes.map((route, idx) => {
									return (
										route.element && (
											<Route
												key={idx}
												index={route.index}
												path={route.path}
												element={<route.element />}
											/>
										)
									);
								})}
							</Route>
						{/* )} */}
						<Route path={"*"} element={<ErrorPage />} />
					</Routes>
				</BrowserRouter>
			</Box>
		</ThemeProvider>
  );
};

export default App;
