//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: react-redux store
//  Workfile:: store.ts
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import { configureStore } from '@reduxjs/toolkit';
import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux'
import contentReducer from "./contentsTitleSlice";
import configReducer from './configJsonSlice';
import userReducer from './userNameSlice';
import currentViewReducer from './currentViewSlice';
import { loadState, saveState } from "./SessionStorage"; // ストレージユーティリティ
;
/****************************************************************************/
/*	定義値                                          						
/****************************************************************************/
// sessionStorageからの初期状態を読み込む
const preloadedState = loadState();
// Reducer定義
export const store = configureStore({
	reducer: {
		contentsTitle: contentReducer, // コンテンツタイトル
		configJson: configReducer, // JSON設定データ
		userName: userReducer, // ユーザー名
		currentView: currentViewReducer // 現在処理画面
  	},
  	preloadedState, // 初期状態にsessionStorageからのデータを使用
});

// 状態が変更されるたびにsessionStorageに保存する
store.subscribe(() => {
  saveState(store.getState());
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
