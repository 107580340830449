//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: コンテンツタイトルSlice
//  Workfile:: contentsTitleSlice.ts
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import { createSlice } from '@reduxjs/toolkit';

/****************************************************************************/
/*	定義値                                          												*/
/****************************************************************************/
export const contentTitleSlice = createSlice({
  name: 'contentTitle',
  initialState: {
    strContentsTitle: "",
  },
  reducers: {
    setContentsTitle: (state, action) => {
      state.strContentsTitle = action.payload;
    },
  },
});

export const { setContentsTitle } = contentTitleSlice.actions;

export default contentTitleSlice.reducer;
