// "use strict";
//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: JSON設定ファイル定義
//  Workfile:: jsonconf.ts
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************
exports.__esModule = true;
exports.makeJsonConfKey = exports.JSON_CONF = void 0;
/****************************************************************************/
/*								Include Files		                        */
/****************************************************************************/
// 735系JSON設定ファイル
var common_config_735 = require('./conf/735/common_config.json');
var destination_735 = require('./conf/735/destination.json');
var fileformat_735 = require('./conf/735/fileformat.json');
var formlist_config_735 = require('./conf/735/formlist_config.json');
var layout_735_air_735 = require('./conf/735/layout_735_air.json');
var layout_735_airsdr_735 = require('./conf/735/layout_735_airsdr.json');
var layout_735_brake_735 = require('./conf/735/layout_735_brake.json');
var layout_735_common_735 = require('./conf/735/layout_735_common.json');
var layout_735_hoso_735 = require('./conf/735/layout_735_hoso.json');
var layout_735_main_735 = require('./conf/735/layout_735_main.json');
var layout_735_siv_735 = require('./conf/735/layout_735_siv.json');
var layout_735_view_735 = require('./conf/735/layout_735_view.json');
var layout_dio_735 = require('./conf/735/layout_dio.json');
var layout_dio_m_735 = require('./conf/735/layout_dio_m.json');
var layout_dio_tc1_735 = require('./conf/735/layout_dio_tc1.json');
var layout_dio_tc2_735 = require('./conf/735/layout_dio_tc2.json');
var troubletable_735 = require('./conf/735/troubletable.json');
var dataset_air_735 = require('./conf/735/dataset/dataset_air.json');
var dataset_base_735 = require('./conf/735/dataset/dataset_base.json');
var dataset_brake_735 = require('./conf/735/dataset/dataset_brake.json');
var dataset_main1_735 = require('./conf/735/dataset/dataset_main1.json');
var dataset_main2_735 = require('./conf/735/dataset/dataset_main2.json');
var dataset_vcb_735 = require('./conf/735/dataset/dataset_vcb.json');
/****************************************************************************/
/*	定義値                                          												*/
/****************************************************************************/
// JSON設定ファイルの連想配列
exports.JSON_CONF = {
    // キー名は拡張子を抜いたファイル名＋車系名を設定
    'common_config735': common_config_735,
    'formlist_config735': formlist_config_735,
    'fileformat735': fileformat_735,
    'destination735': destination_735,
    'layout_735_common735': layout_735_common_735,
    'layout_735_view735': layout_735_view_735,
    'layout_dio735': layout_dio_735,
    'layout_735_brake735': layout_735_brake_735,
    'layout_735_airsdr735': layout_735_airsdr_735,
    'layout_735_air735': layout_735_air_735,
    'layout_735_siv735': layout_735_siv_735,
    'layout_735_main735': layout_735_main_735,
    'layout_735_hoso735': layout_735_hoso_735,
    'layout_dio_m735': layout_dio_m_735,
    'layout_dio_tc1735': layout_dio_tc1_735,
    'layout_dio_tc2735': layout_dio_tc2_735,
    'troubletable735': troubletable_735,
    'dataset_air735': dataset_air_735,
    'dataset_base735': dataset_base_735,
    'dataset_brake735': dataset_brake_735,
    'dataset_main1735': dataset_main1_735,
    'dataset_main2735': dataset_main2_735,
    'dataset_vcb735': dataset_vcb_735
};
//****************************************************************************
//++module		makeJsonConfKey
//----------------------------------------------------------------------------
//++outline
//				JSON_CONFのキー生成処理
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
function makeJsonConfKey(strShakei, strConfig) {
    var strKey = "";
    if (strConfig !== '') {
        var astrFileSplit = strConfig.split("/").reverse()[0].split('.');
        var strFnName = astrFileSplit[0]; // ファイル名抽出
        strKey = strFnName + strShakei; // ファイル名＋車系名でキーを生成
    }
    return strKey;
}
exports.makeJsonConfKey = makeJsonConfKey;
