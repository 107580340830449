//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: パスワード入力ページ表示
//  Workfile:: UserAction.tsx
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files								                              */
/****************************************************************************/
import React, { useState, useEffect } from "react";
import { Alert, Box } from "@mui/material";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./View/Login/Firebase";
import * as common from "./utils/common";

/****************************************************************************/
/*	定義値                                          						            */
/****************************************************************************/
const MODE_RESET_PASSWORD = "resetPassword";  // モード：パスワード再設定
const RES_RETRY = 1;                          // 結果：再設定
const RES_SUCCESS = 2;                        // 結果：設定終了
const RES_ERROR = 3;                          // 結果：設定失敗

//****************************************************************************
//++module		getParameterByName
//----------------------------------------------------------------------------
//++outline
//				URLから指定したクエリのバリューを抜き出す
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
const getParameterByName = (name: string) => {
  const url = window.location.href;
  name = name.replace(/[[]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)

  if(!results){
    return null
  }

  if(!results[2]){
    return ""
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

//****************************************************************************
//++module		UserAction
//----------------------------------------------------------------------------
//++outline
//				モードごとアクションページ表示
//				mode：resetPassword   パスワードの再設定ページ
//++arguments
//
//++supp
//
//++end_module
//****************************************************************************
const UserAction = () => {
  const [state, setState] = useState<{
    mode: string
    actionCode: string
  } | null>(null)
  const [nResult, setResult] = useState(0);     // 結果
  const [strEmail, setEmail] = useState("");    // ユーザーのメールアドレス
  const navigate = useNavigate();

  //****************************************************************************
  //++module		MovePage
  //----------------------------------------------------------------------------
  //++outline
  //				ページ遷移処理
  //++arguments
  //
  //++supp
  //
  //++end_module
  //****************************************************************************
  const MovePage = async () => {
    let strPath = "";

    if(nResult === RES_SUCCESS){
      // ログイン画面に遷移
      strPath = common.PAGE_LOGIN;
    } else {
      // パスワード再設定画面に遷移
      strPath = common.PAGE_PASSWORDRESET;
    }
    navigate(strPath);
  };

  //****************************************************************************
  //++module		useEffect
  //----------------------------------------------------------------------------
  //++outline
  //				useEffect処理
  //++arguments
  //
  //++supp
  //
  //++end_module
  //****************************************************************************
  useEffect(() => {
    // アドレスからモードとトークンを取得
    const mode = getParameterByName("mode") || "";
    const actionCode = getParameterByName("oobCode") || "";

    // メールアドレスの取得
    verifyPasswordResetCode(auth, (actionCode || ""))
    .then(function (email) {
      setEmail(email);
    }).catch((err) => {
      // エラーの原因としてはトークンの有効期限切れ、もしくは使用済み
        setResult(RES_ERROR);
    });

    setState({
      mode,
      actionCode,
    })
  }, [])

  //****************************************************************************
	//++module		handleSubmit
	//----------------------------------------------------------------------------
	//++outline
	//				ボタン処理
	//++arguments
	//
	//++supp
	//
	//++end_module
	//****************************************************************************
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as any
    const newPassword = target.password.value as string

    verifyPasswordResetCode(auth, (state?.actionCode || ""))
    .then((email) => {
      confirmPasswordReset(auth, (state?.actionCode || ""), newPassword)
      .then(async resp => {
        setResult(RES_SUCCESS);
      })
      .catch((error) => {
        // エラーの原因としてはパスワードが6文字以下、もしくは弱すぎるパスワード
          setResult(RES_RETRY);
      })
    })
    .catch((error) => {
      // エラーの原因としてはトークンの有効期限切れ、もしくは使用済み
      setResult(RES_ERROR);
    })
  };

  return (
    <div>
      {state?.mode === MODE_RESET_PASSWORD ? (
        // パスワードの再設定
        <>
        {(nResult <= RES_RETRY) ? (
          // 新しいパスワードの設定
          <div className="container">
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <h1>パスワード再設定</h1>
              <div>
                <label>メールアドレス：{strEmail}</label>
                <br/>
                <br/>
                <label>新しいパスワード</label>
                <input name="password" type="password" />
              </div>
              {nResult === RES_RETRY && (
                <Alert severity="error">6文字以上で、文字と数字を組み合わせた安全なパスワードを設定してください</Alert>
              )}
              <button>保存</button>
            </Box>
          </div>
        ) : (nResult === RES_SUCCESS) ? (
          // パスワード設定完了
          <div className="container">
            <h1>パスワードを変更しました</h1>
            <p>新しいパスワードでログインできるようになりました</p>
            <button onClick={MovePage}>ログイン画面へ</button>
          </div>
        ) : (
          // パスワード設定失敗
          <div className="container">
            <h2>パスワードの再設定をもう一度お試しください</h2>
            <p>パスワードの再設定のリクエストの期限が切れたか、リンクがすでに使用されています</p>
            <button onClick={MovePage}>パスワード再設定画面へ</button>
          </div>
        )}
        </>
      )/* カスタムアクションURLは、メールテンプレートすべてに適用されるため、
          メールアドレスの確認など、他の機能を実装する場合は、ここに追加 
       : state?.mode === "" ? (
        mode毎の処理
      ) */ : (
        <div>error: modeが選択されていない不正なURLです。</div>
      )}
    </div>
  )
}

export default UserAction;